import { LOCALE } from '@hhgtech/hhg-components/types'
import * as Sentry from '@sentry/node'
import { PATHS } from 'api/paths'
import axios, { AxiosRequestConfig, Method } from 'axios'
import cookieParser from 'cookie'
import { domainLocales } from 'domainLocales'
import {
  BEARER_TOKEN_COOKIE,
  STATIC_PAGES,
  REDIRECTION_PAGES,
  CORAL_TOKEN_COOKE,
  LEAD_TOKEN_COOKIE,
  GA_TOKEN_COOKIE,
} from 'interfaces/constants'
import { AnchorRef, LocaleType, UserInfo } from 'interfaces/types'
import Cookies from 'js-cookie'
import { GetServerSidePropsResult } from 'next'
import _formatString from 'string-format'
import { v4 as uuidv4 } from 'uuid'

const formatString = (...params: Parameters<typeof _formatString>) => {
  const formatString = _formatString(...params)
  if (formatString.includes('?')) {
    const [url, query] = formatString.split('?', 2)
    const queryParams = query
      .split('&')
      .filter((param) => param && (!param.includes('=') || param.split('=')[1]))
    if (queryParams.length === 0) {
      return url
    }
    return `${url}?${queryParams.join('&')}`
  }
  return formatString
}
export const locale = (process.env.NEXT_PUBLIC_DEPLOY_LOCALE ||
  'vi-VN') as LOCALE

export const getCurrentBaseUrl = (locale: string) => {
  // if (typeof window !== 'undefined') return window.location.origin
  const isStaging = process.env.NEXT_PUBLIC_DEPLOY_ENV === 'staging'
  const isDev = process.env.NEXT_PUBLIC_DEPLOY_ENV === 'development'
  return isDev
    ? 'http://localhost:3000'
    : `https://${
        isStaging
          ? domainLocales[locale].replace('www', 'fe')
          : domainLocales[locale]
      }`
}

export const getApiPath = (
  path: string,
  params?: { [key: string]: string | number },
) => {
  const { NEXT_PUBLIC_BASE_API_URL, WP_INTERNAL_API } = process.env
  const apiUrl = WP_INTERNAL_API || NEXT_PUBLIC_BASE_API_URL
  return apiUrl + 'wp-json/api/' + formatString(path ?? '', { ...params })
}

export const getApiTrackingPath = (
  path: string,
  params?: { [key: string]: string | number },
) => {
  return (
    process.env.NEXT_PUBLIC_BASE_API_URL +
    'wp-json/' +
    formatString(path, { ...params })
  )
}

export const getSSOPath = (
  path: string,
  params?: { [key: string]: string | number },
) => {
  return (
    process.env.NEXT_PUBLIC_SSO + 'api/' + formatString(path, { ...params })
  )
}

export const getSidisUpdatePath = (
  path: string,
  params?: { [key: string]: string | number },
) => {
  return (
    process.env.NEXT_PUBLIC_SIDIS_API +
    'api/' +
    formatString(path, { ...params })
  )
}

export const getSidisPath = (
  path: string,
  params?: { [key: string]: string | number },
) => {
  return (
    process.env.NEXT_PUBLIC_SIDIS_API +
    'api/' +
    formatString(path, { ...params })
  )
}

export const getLeadPath = (
  path: string,
  params?: { [key: string]: string | number },
) => {
  return (
    process.env.NEXT_PUBLIC_LEAD_API +
    'api/' +
    formatString(path, { ...params })
  )
}

export const getTogetherPath = (
  path: string,
  params?: { [key: string]: string | number },
  version?: string,
) => {
  return (
    process.env.NEXT_PUBLIC_TOGETHER_API +
    `api/${version || 'v1'}/` +
    formatString(path, { websiteUrl: 'https://marrybaby.vn', ...params })
  )
}

export const createAxiosWithInterceptor = (
  token: string,
  tokenType: string,
) => {
  const apiInstance = axios.create()
  apiInstance.interceptors.request.use(
    (config) => {
      config.headers = {
        Authorization: `${tokenType} ${token}`,
      }
      return config
    },
    (error) => {
      Promise.reject(error)
    },
  )

  return apiInstance
}
const fixedEncodeURI = (str: string) => {
  return encodeURI(str).replace(/%5B/g, '[').replace(/%5D/g, ']')
}

export const callApi = async (
  url: string,
  method: Method,
  options?: AxiosRequestConfig,
  skipEncodeURI = false,
) => {
  try {
    const res = await axios({
      url: skipEncodeURI ? url : fixedEncodeURI(url),
      method,
      ...options,
      timeout: 10000,
    })
    if (res) return res.data?.status !== -1 ? res.data : null
  } catch (err) {
    Sentry.captureException(err)
    return null
  }
  return null
}

export const callApiWithOptionalAuth = async (
  url: string,
  noAuthUrl?: string,
  method: Method = 'GET',
  options?: AxiosRequestConfig,
  reqCookie?: string,
) => {
  try {
    const parsedCookie = cookieParser.parse(reqCookie || '')
    const token = parsedCookie[BEARER_TOKEN_COOKIE]
    const cookie = token || Cookies.get(BEARER_TOKEN_COOKIE)

    if (cookie) {
      try {
        const authAxios = createAxiosWithInterceptor(cookie, 'Bearer')
        const res = await authAxios({ url, method, ...options, timeout: 10000 })
        return res?.data ?? null
      } catch (e) {
        //
      }
      if (noAuthUrl) {
        return await callApi(noAuthUrl || url, method, options)
      }
      return null
    } else if (noAuthUrl) {
      return await callApi(noAuthUrl || url, method, options)
    }
    return null
  } catch (e) {
    //
  }
  return null
}

export const callApiWithAuth = async (
  url: string,
  method: Method,
  options?: AxiosRequestConfig,
  reqCookie?: string,
) => {
  try {
    const parsedCookie = cookieParser.parse(reqCookie || '')
    const token = parsedCookie[BEARER_TOKEN_COOKIE]
    const cookie = token || Cookies.get(BEARER_TOKEN_COOKIE)
    if (cookie && cookie.length) {
      const authAxios = createAxiosWithInterceptor(cookie, 'Bearer')
      const res = await authAxios({ url, method, ...options, timeout: 10000 })
      if (res) return res.data?.status !== -1 ? res.data : null
    }
  } catch (err) {
    Sentry.captureException(err)
    return null
  }
  return null
}
export const callApiWithLead = async (
  url: string,
  method: Method,
  options?: AxiosRequestConfig,
  reqCookie?: string,
) => {
  try {
    const parsedCookie = cookieParser.parse(reqCookie || '')
    const token = parsedCookie[LEAD_TOKEN_COOKIE]
    let cookie = token || Cookies.get(LEAD_TOKEN_COOKIE)
    const ga = parsedCookie[GA_TOKEN_COOKIE] || Cookies.get(GA_TOKEN_COOKIE)

    if (!cookie) {
      const newCookie = uuidv4()
      cookie = newCookie
      Cookies.set(LEAD_TOKEN_COOKIE, newCookie)
    }

    const res = await axios({
      url: fixedEncodeURI(url),
      method,
      ...options,
      data: {
        ...options?.data,
        cookie_id: cookie,
        ga_client_id: ga || '',
      },
      timeout: 10000,
    })
    if (res) return res.data?.status !== -1 ? res.data : null
  } catch (err) {
    Sentry.captureException(err)
    return null
  }
  return null
}

export const authorize = async (cookie: string): Promise<UserInfo | null> => {
  try {
    const parsedCookie = cookieParser.parse(cookie)
    const token = parsedCookie[BEARER_TOKEN_COOKIE]
    if (token && token.length) {
      const axiosInstance = createAxiosWithInterceptor(token, 'Bearer')
      const response = await axiosInstance.post(getSSOPath(PATHS.GET_PROFILE))
      if (response) {
        return response.data._data
      }
    }
  } catch (err) {
    return null
  }

  return null
}

export const getCoralToken = (cookie?: string): string | undefined => {
  if (!cookie) return undefined
  const parsedCookie = cookieParser.parse(cookie)
  const token = parsedCookie[CORAL_TOKEN_COOKE]

  return token
}

export const checkMobile = (userAgent: string): boolean => {
  if (/Tablet|iPad/i.test(userAgent)) {
    return false
  }
  return (
    /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(userAgent) ||
    /\b(Android|Windows Phone|iPod)\b/i.test(userAgent)
  )
}

export const checkCrawler = (userAgent?: string): boolean => {
  if (!userAgent) return false
  const botPattern =
    'bot|crawler|spider|crawling|Chrome-Lighthouse|Page Speed Insights'
  const reg = new RegExp(botPattern, 'i')
  return reg.test(userAgent)
}

export const verifyNotFoundToRedirect = (
  slug: string | string[] | undefined,
  locale: string,
): GetServerSidePropsResult<any> => {
  // redirect pagination
  if (slug && slug?.length > 2 && slug?.slice(-2)) {
    const checkingSlug = slug?.slice(-2)
    if (
      checkingSlug[0] === 'page' &&
      parseInt(checkingSlug[1]) &&
      !isNaN(parseInt(checkingSlug[1]))
    ) {
      ;(slug as string[]).splice(-2, 2)
      ;(slug as string[]).push(`?${checkingSlug[0]}=${checkingSlug[1]}`)
      const redirectUrl = `/${(slug as string[]).join('/')}`

      return {
        redirect: {
          destination: redirectUrl,
          permanent: true,
        },
      }
    }
  }

  // redirect old static to new
  if (slug && slug?.length > 0 && slug?.slice(-1)) {
    const checkingSlug = slug?.slice(-1) as string

    const staticPage = Object.values(STATIC_PAGES[locale as LocaleType] || {})
      .filter(Boolean)
      .find((page) => {
        return (page as string).search(checkingSlug) > -1
      })

    if (staticPage) {
      const redirectUrl = `/static${encodeURI(staticPage)}`

      return {
        redirect: {
          destination: redirectUrl,
          permanent: true,
        },
      }
    }
  }

  // custom redirect for each region
  if (slug) {
    const checkingSlug =
      slug.length > 0 ? `${(slug as string[]).join('/')}` : `${slug}`
    const redirectedPage = (REDIRECTION_PAGES[locale] || {})[
      Object.keys(REDIRECTION_PAGES[locale] || {}).find(
        (page) => page.search(checkingSlug) > -1,
      ) || 'none'
    ]

    if (redirectedPage) {
      return {
        redirect: {
          destination: encodeURI(redirectedPage),
          permanent: true,
        },
      }
    }
  }

  return {
    notFound: true,
  }
}

export const redirectToSignUp = (source?: string) => {
  const { NEXT_PUBLIC_SSO } = process.env
  const currentLocation = window.location.href
  const sourceRedirect = !!source
    ? `${currentLocation.includes('?') ? '&' : '?'}source=${source}`
    : ''
  window.location.href = NEXT_PUBLIC_SSO
    ? `${NEXT_PUBLIC_SSO}?returnUrl=${encodeURIComponent(
        `${currentLocation}${sourceRedirect}`,
      )}`
    : currentLocation
}

export const redirectToSSOWithReturnUrl = (
  url?: string,
  source?: string,
  action?: string,
  campaign?: string,
) => {
  const { NEXT_PUBLIC_SSO } = process.env

  const originalRedirectUrl = url || window.location.href

  const sourceRedirect = !!source
    ? `${originalRedirectUrl.includes('?') ? '&' : '?'}source=${source}`
    : ''

  const actionRedirect = !!action
    ? `${
        originalRedirectUrl.includes('?') || !!sourceRedirect ? '&' : '?'
      }action=${action}`
    : ''

  const campaignRedirect = !!action
    ? `${
        originalRedirectUrl.includes('?') || !!sourceRedirect ? '&' : '?'
      }campaign=${campaign ?? ''}`
    : ''

  window.location.href = NEXT_PUBLIC_SSO
    ? `${NEXT_PUBLIC_SSO}?returnUrl=${encodeURIComponent(
        `${originalRedirectUrl}${sourceRedirect}${actionRedirect}${campaignRedirect}`,
      )}`
    : '/'
}

export const getUrlToRedirectToSSOWithReturnUrl = (
  url?: string,
  source?: string,
  action?: string,
): string => {
  if (typeof window === 'undefined') {
    return ''
  }
  const { NEXT_PUBLIC_SSO } = process.env

  const originalRedirectUrl = url || window.location.href

  const sourceRedirect = !!source
    ? `${originalRedirectUrl.includes('?') ? '&' : '?'}source=${source}`
    : ''

  const actionRedirect = !!action
    ? `${
        originalRedirectUrl.includes('?') || !!sourceRedirect ? '&' : '?'
      }action=${action}`
    : ''
  return NEXT_PUBLIC_SSO
    ? `${NEXT_PUBLIC_SSO}?returnUrl=${encodeURIComponent(
        `${originalRedirectUrl}${sourceRedirect}${actionRedirect}`,
      )}`
    : '/'
}

export const handleGoogleOauthRedirect = () => {
  const access_token = extractParam(window.location.hash, 'access_token')
  const state = extractParam(window.location.hash, 'state')
  if (state) {
    const stateObj = new URLSearchParams(decodeURIComponent(state))
    const redirectValue = stateObj.get('redirect')
    if (redirectValue) {
      window.location.href =
        decodeURIComponent(redirectValue) +
        (decodeURIComponent(redirectValue).includes('?') ? '&' : '?') +
        'access_token=' +
        access_token
    }
  }
}

export const extractParam = (targetString: string, name: string) => {
  const targetProp = name + '='
  const targetPropIndex = targetString.indexOf(targetProp)
  if (targetPropIndex !== -1) {
    const value = targetString.slice(
      targetPropIndex + targetProp.length,
      targetPropIndex + targetString.slice(targetPropIndex).indexOf('&') ||
        undefined,
    )
    return value
  }
  return null
}

export const getSubotApiPath = (
  path: string,
  params?: { [key: string]: string | number },
) => {
  const { NEXT_PUBLIC_SUBOT_API } = process.env
  const apiUrl = NEXT_PUBLIC_SUBOT_API
  // typeof window === 'undefined'
  //   ? SUBOT_INTERNAL || NEXT_PUBLIC_SUBOT_API
  //   : NEXT_PUBLIC_SUBOT_API
  return apiUrl + formatString(path, { ...params })
}

export const getSidisGetPath = (
  path: string,
  params?: { [key: string]: string | number },
) => {
  const { NEXT_PUBLIC_SIDIS_API_GET } = process.env
  return (
    NEXT_PUBLIC_SIDIS_API_GET + 'frontend/' + formatString(path, { ...params })
  )
}

export const getTarotStrapiPath = (
  path: string,
  params?: { [key: string]: string | number },
) => {
  const { NEXT_PUBLIC_TAROT_STRAPI_API_GET } = process.env
  return (
    NEXT_PUBLIC_TAROT_STRAPI_API_GET +
    '/api/' +
    formatString(path, { ...params })
  )
}

export const getUrlInfo = async (href: string) => {
  const match = href.match(
    /^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/,
  )
  return match && match[1] + '//' + match[2] + match[5]
}

export const getHellositeUrl = (locale: string) => {
  const { NEXT_PUBLIC_DEPLOY_ENV: DEPLOY_ENV } = process.env

  const isProd = DEPLOY_ENV === 'production'

  return `https://${isProd ? '' : 'discover.'}${domainLocales[locale]}`
}

export const getMarryBabyUrl = (locale: string) => {
  const { NEXT_PUBLIC_DEPLOY_ENV: DEPLOY_ENV } = process.env

  const isProd = DEPLOY_ENV === 'production'

  return `https://${isProd ? 'www.' : 'fe.'}${domainLocales[locale]}`
}

export function instanceOfAnchorRef(object: any): object is AnchorRef {
  if (!object || typeof object !== 'object') return false
  return 'label' in object && 'id' in object
}

/** To make sure have the same window id until refresh the page */
const generateWindowId = uuidv4()
export const getWindowId = () => {
  return generateWindowId
}

export const validateEmail = (mail: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(mail).toLowerCase())
}

export const validateURL = (url: string) => {
  try {
    new URL(url)
    return true
  } catch (error) {
    return false
  }
}

// TODO: improve this function
export const validateName = (name: string) => {
  const re = /^[^_\d.!@#$%^&*()+=.,]+$/

  return re.test(name)
}

export const getBabyVaccineToolPath = (
  path: string,
  params?: { [key: string]: string | number },
) => {
  const INTERNAL_VACCINATION_TOOL_API =
    process.env.INTERNAL_VACCINATION_TOOL_API
  const NEXT_PUBLIC_VACCINATION_TOOL_API =
    process.env.NEXT_PUBLIC_VACCINATION_TOOL_API
  return (
    (INTERNAL_VACCINATION_TOOL_API || NEXT_PUBLIC_VACCINATION_TOOL_API) +
    formatString(path, {
      ...params,
      domain: domainLocales[locale],
    })
  )
}
export const getHelloSitesUrl = (locale: LOCALE) => {
  const { NEXT_PUBLIC_DEPLOY_ENV: DEPLOY_ENV } = process.env

  const isProd = DEPLOY_ENV === 'production'

  return `https://${isProd ? '' : 'fe.'}${domainLocales[locale]}`
}
export const cleanUnicodeUrl = (s: string) =>
  s
    .split('/')
    .map((s) => (s.includes('%') ? s : encodeURI(s)))
    .join('/')

export const getCareDomain = (locale: LOCALE) => {
  const { NEXT_PUBLIC_DEPLOY_ENV } = process.env

  const isProduction = NEXT_PUBLIC_DEPLOY_ENV === 'production'

  if (isProduction) {
    return `${domainLocales[locale]}/care`
  }

  return `discover.${domainLocales[locale]}/care`
}

export const getUserIdFromCookie = (reqCookie?: string) => {
  const parsedCookie = cookieParser.parse(reqCookie || '')
  const reqToken = parsedCookie[BEARER_TOKEN_COOKIE]
  const token = reqToken || Cookies.get(BEARER_TOKEN_COOKIE) || ''

  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join(''),
    )

    return JSON.parse(jsonPayload).sub
  } catch (err) {
    return ''
  }
}

export const getHhgIdFromCookie = (reqCookie?: string) => {
  const parsedCookie = cookieParser.parse(reqCookie || '')
  const reqHhgId = parsedCookie['hhg-id']

  return reqHhgId || Cookies.get('hhg-id') || ''
}

export const getSubotCookieId = (queryCookieId?: string) => {
  if (queryCookieId) {
    Cookies.set('hhg-id', queryCookieId)
    return queryCookieId
  }

  const cookieId = getHhgIdFromCookie()

  if (cookieId) {
    return cookieId
  }

  const newCookieId = uuidv4()
  Cookies.set('hhg-id', newCookieId)
  return newCookieId
}

export const getUserInfoFromCookie = (reqCookie?: string) => {
  const parsedCookie = cookieParser.parse(reqCookie || '')
  const reqToken = parsedCookie[BEARER_TOKEN_COOKIE]
  const token = reqToken || Cookies.get(BEARER_TOKEN_COOKIE) || ''

  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join(''),
    )
    const payload = JSON.parse(jsonPayload)
    return { email: payload.email, id: payload.sub }
  } catch (err) {
    return { email: '', id: 0 }
  }
}

export const formatGenderUrl = (gender: string) => {
  if (gender === 'male') return 'm'
  return 'f'
}
