import { NavigationV2 } from '@hhgtech/hhg-components'
import styled from 'styled-components'

export const StyledNavigationV2 = styled(NavigationV2)`
  &.nav-tarot-game {
    #site-header {
      background-color: transparent;

      .hhgcomp-navigation-itemnav-headeritem {
        border-bottom: none;
      }
    }
  }
`
