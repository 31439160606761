const SSO = {
  POST_FCM_TOKEN_AUTH: 'user/webpush',
  POST_FCM_TOKEN: 'webpush',
}

export const PATHS = {
  BASE_API_PATH: '/',
  GET_SEARCH_ARTICLES: 'search?s={keyword}&page={page}',
  // GET_TOP_CATEGORIES: 'categories',
  GET_CATEGORIES_LEVEL_1: 'categories?_level=1',
  GET_ALL_CATEGORIES: 'categories?type=all',
  GET_HOMEPAGE_POSTS: 'home/trending-post?type={type}',
  GET_HOMEPAGE_LATEST_POSTS: 'articles?page=1&_islatest=1',
  GET_ARTICLE_DETAIL:
    'article/{slug}?skip_ids={skipIds}&_isrelated={isRelated}',
  GET_CATEGORY_DETAIL: 'category/{slug}',
  GET_CATEGORY_OR_ARTICLE_DETAIL: 'fetch/{fullSlug}',
  GET_PREVIEW_ARTICLE: 'fetch/{fullSlug}?preview_nonce={nonce}&preview=true',
  GET_ARTICLE_OF_CATEGORY:
    'articles?category_id={categoryId}&page={currentPage}',
  GET_PAGE_STATIC: 'page/{slug}',
  GET_ALL_HEALTH_TOOLS: 'health-tools',
  GET_HEALTH_TOOLS_BY_CATEGORY: 'health-tools?_hasgroup=1',
  GET_AUTHOR: 'author/{slug}',
  GET_ALL_EXPERTS: 'experts',
  GET_ALL_EXPERTS_PAGING: 'experts?page={page}&number={perPage}',
  GET_ALL_EXPERTS_HEADING: 'expert/heading',
  GET_EXPERT: 'expert/{slug}',
  GET_EXPERT_POST: 'expert/article?page={page}&per_page={perPage}',
  POST_ARTICLE_FEEDBACK: 'article/feedback/{id}',
  GET_AZ_DRUGS: 'category/a-z/{category}',
  GET_HOMEPAGE_MEDICAL_REVIEWED: 'home/medical-reviewed',
  GET_ARTICLE_OF_CATEGORY_NO_EXPLAINERS:
    'articles?category_id={categoryId}&page={currentPage}&skip_ids={ids}',
  GET_EXPERT_OF_CATEGORY: 'category/expert/?id={id}',
  GET_DUE_DATE_INFO: 'health-tools/due-date-calculator/info',
  GET_DUE_DATE_ALL_WEEKS: 'health-tools/due-date-calculator/all',
  GET_DUE_DATE_SUGGESTED_ARTICLES:
    'health-tools/due-date-calculator/result?from_week={from}&to_week={to}',
  GET_PROFILE: 'user/profile',
  GET_TOKENS_UID2: 'user/uid2',
  GET_FEATURE: 'feature',
  POST_NEW_FEATURE: 'feature/update',
  CREATE_BOOKMARK: 'bookmark/create',
  DELETE_BOOKMARK: 'bookmark/delete',
  DELETE_ALL_BOOKMARK: 'bookmark/delete-all',
  SSO_UPDATE_FIRST_TIME_BOOKMARK: 'bookmark/update-first-time',
  GET_ALL_BOOKMARK: 'bookmark',
  SSO_USER_PROFILE: 'user',
  SSO_USER_BOOKMARKS: 'user/bookmarks',
  SSO_USER_PASSWORD: 'user/settings/password',
  SSO_USER_EMAIL: 'user/settings/update-email',
  SSO_CLOSE_ACCOUNT: 'user/settings/close-account',
  SSO_HELP: 'user/settings/help',
  SSO,
  GET_HEALTH_TOOL_OVULATION_INFO: 'health-tools/ovulation-calculator/info',
  GET_HEALTH_TOOL_OVULATION_SUGGESTED_ARTICLES:
    'health-tools/ovulation-calculator/result?method={method}', // method: 1, 2, 3
  GET_HEALTH_TOOL_BMI_INFO: 'health-tools/bmi-calculator/info',
  GET_HEALTH_TOOL_BMI_RESULT:
    'health-tools/bmi-calculator/result?gender={gender}&bmi={bmi}&age={age}',
  GET_HEALTH_TOOL_BMR_INFO: 'health-tools/bmr-calculator/info',
  GET_HEALTH_TOOL_BMR_RESULT:
    'health-tools/bmr-calculator/result?kcals={kcals}&gender={gender}&age={age}',
  POST_UPDATE_LOCATION: 'tracking/geo/',
  GET_ADS_TXT: 'adspage',

  // SIDIS UPDATE
  POST_SIDIS_DUE_DATE: 'frontend/tools/due_dates',
  POST_SIDIS_OVULATION: 'frontend/tools/ovulations',
  POST_SIDIS_BMI: 'frontend/tools/bmis',
  POST_SIDIS_BMR: 'frontend/tools/bmrs',
  POST_SIDIS_THR: 'frontend/tools/heart-rates',
  POST_SIDIS_PWG: 'frontend/tools/pregnancies',

  // SIDIS
  GET_SIDIS_DUE_DATE: 'frontend/tools/due_date',
  GET_SIDIS_OVULATION: 'frontend/tools/ovulation',
  GET_SIDIS_BMI: 'frontend/tools/bmi',
  GET_SIDIS_BMR: 'frontend/tools/bmr',
  GET_SIDIS_RISK_SCREENERS_ID: 'api/my-profile/my-health/risk-screeners/{id}', // get risk-screeners by id from sidis
  GET_SIDIS_PWG: 'api/pregnancies/{resultId}',
  GET_SIDIS_HEALTH_INFO: 'api/my-profile/health-info',
  POST_LOGOUT: 'user/logout',
  OVULATION_CREATE_EVENT:
    'ovulation/event?method={method}&day={day}&cycle={cycle}&period={period}&token={token}&user_id={user_id}',
  POST_COMMENT_NOTIFICATION: 'comment/notification',
  GET_ABOUT_US_DATA: 'about',
  POST_LEAD_IMPRESSION: 'campaign/{campaignCode}/impression',
  POST_LEAD_POPUP_EXIT: 'campaign/{campaignCode}/popup_exit',
  POST_LEAD_LEAD: 'campaign/{campaignCode}/lead',
  POST_SSO_VERIFY_TOKEN: 'verify-id-token',

  // SUBOT
  GET_LANDING_PAGE_FIRST_NODE: 'landing_page/bot',
  GET_LANDING_PAGE_NEXT_NODE: 'landing_page/messages',
  POST_LANDING_PAGE_IMPRESSION: 'landing_page/impressions',
  POST_LANDING_PAGE_CLICK: 'landing_page/clicks',
  GET_SUBOT_LOG: 'log/bot/{botId}/{cookieId}/{accountId}',
  POST_SUBOT_CREATE_LOG: 'log/bot',

  // OTP Subot
  POST_SEND_OTP: 'sms/otp',
  POST_VERIFY_OTP: 'sms/verify',

  FETCH_URL_DATA: 'preview',
  GET_SPOTLIGHT_LANDING: 'spotlight/{slug}',
  GET_ALL_SPOTLIGHT: 'spotlights?per_page={perPage}&order_by={orderBy}',
  GET_HOMEPAGE_SPOTLIGHTS: 'spotlights',
  GET_TOGETHER_COMMUNITY: 'community?website_url={websiteUrl}',
  GET_TOGETHER_COMMUNITY_AUTH: 'community?website_url={websiteUrl}',
  GET_TOGETHER_HOMEPAGE_POSTS:
    'post?page=1&website_url={websiteUrl}&page=1&sort_by=newest',
  GET_TOGETHER_HOMEPAGE_POSTS_AUTH:
    'post?page=1&website_url={websiteUrl}&page=1&sort_by=newest',
  GET_TOGETHER_DETAIL_POST:
    'post?community_id={communityId}&website_url={websiteUrl}',
  GET_TOGETHER_TOPICS:
    'topic?community_id={communityId}&website_url={websiteUrl}&due_date_month={dueDateMonth}&due_date_year={dueDateYear}&show_hidden={showHidden}',
  GET_TOGETHER_COMMUNITY_DETAIL:
    'community/{communityId}?website_url={websiteUrl}',
  GET_TOGETHER_COMMUNITY_DETAIL_AUTH:
    'community/{communityId}?website_url={websiteUrl}',
  GET_TOGETHER_SUGGESTED_POST:
    'suggested-post?topic_id={topicId}&website_url={websiteUrl}',
  POST_TOGETHER_JOIN_COMMUNITY: 'community/join?website_url={websiteUrl}',
  POST_TOGETHER_LEAVE_COMMUNITY: 'community/leave?website_url={websiteUrl}',
  POST_TOGETHER_JOIN_TOPIC: 'topic/join?website_url={websiteUrl}',
  POST_TOGETHER_LEAVE_TOPIC: 'topic/leave?website_url={websiteUrl}',
  GET_TOGETHER_POST_LIKE: 'post/{postId}/like?website_url={websiteUrl}',
  GET_TOGETHER_USER_AUTHORIZE: 'profile?website_url={websiteUrl}',
  GET_PROVINCE: 'provinces?country_code={countryCode}',
  GET_DISTRICT: 'districts?parent_id={parentId}',
  GET_COMMUNE: 'communes?parent_id={parentId}',
  GET_HOMEPAGE_SITEWIDE_BANNER: 'home/sitewide-banner',
  GET_WEEK_PREGNANCY_PROGRESS: 'article/pregnancy-progression',
  GET_DYNAMIC_FOOTER: 'footer',
  GET_USER_INFO: 'user/{slug}',
  GET_DROPDOWN_TOPICS: 'home/category-dropdown',
  GET_AFFILIATE_PRODUCT: 'product/detail/{productId}',
  GET_LANDING_PAGE: 'landing-page/{slug}/',
  POST_CAMPAIGN_LEAD: `campaign/{leadCampaignCode}/lead`,

  GET_EXPERTS: 'experts?page={page}&number={perPage}&s={search}',
  GET_EXPERT_BY_ID: 'expert/{id}',
  GET_HEALTH_TOOL_BABY_GROWTH_INFO: 'health-tools/baby-growth-tracking/info',
  GET_HEALTH_TOOL_BABY_GROWTH_RESULT:
    'health-tools/baby-growth-tracking/result',
  GET_HEALTH_TOOL_BABY_VACCINE_LIST: 'frontend/api/babies?site={domain}',
  ADD_MEASUREMENT: 'frontend/api/babies/{babyId}/measurements?site={domain}',
  GET_TOGETHER_POST_LIST:
    'post?community_id={communityId}&website_url={websiteUrl}&sort_by={sortBy}&article_id={articleId}&page={page}&report_start_date={reportStartDate}&report_end_date={reportEndDate}&post_by={postBy}',
  GET_HEALTH_TOOL_PWG_INFO: 'health-tools/preweight-gain-calculator/infor',
  GET_HEALTH_TOOL_PWG_RESULT:
    'health-tools/preweight-gain-calculator/result?bmi={bmi}&week={week}&weight_gain={pwg}&type={type}',
  GET_HEALTH_TOOL_BABY_POOP: 'health-tools/baby-poop-tool',

  // baby vaccine
  GET_HEALTH_TOOL_BABY_VACCINE_INFO: 'health-tools/vaccination/info',
  GET_HEALTH_TOOL_BABY_VACCINE_RESULT: 'health-tools/vaccination/result',
  GET_HEALTH_TOOL_BABY_VACCINE_TIMELINE: 'health-tools/vaccination/timeline',
  GET_HEALTH_TOOL_BABY_VACCINE_WITH_ID:
    'health-tools/vaccination/vaccine/{vaccineId}',

  // Schema
  GET_HOMEPAGE_SCHEMA: 'home/schema?website_url={websiteUrl}',
  // Tarot
  /**
   * TODO: Ticket CORE-773
   * Temporary
   */
  GET_TAROT_LIST:
    'tarot-cards?populate[Card][populate][Image]={populateImage}&populate[Card][populate][Description][populate][Topic][fields]={populatefields}&populate[Card][populate][Description][populate][Text_variation]={populateTextVariation}',
  GET_TOPIC_TAROT: 'tarot-topics?populate={populate}',
}

export const TOGETHER_PATHS = {
  GET_POST_LIST_BY_ARTICLE_ID:
    'post?website_url={websiteUrl}&sort_by={sortBy}&article_id={articleId}&page={page}&items_per_page={perPage}',
  GET_POST_COMMENT_BY_ID:
    'comment?website_url={websiteUrl}&post_id={postId}&page={page}&sort_by={sort}&items_per_page={perPage}&_without_reply={withoutReply}',
  COMMENT: {
    LIST:
      'comment?website_url={websiteUrl}&post_id={postId}&page={page}&sort_by={sort}&items_per_page={perPage}&_without_reply={withoutReply}',
  },
}

export const DISCOVER_PATHS = {
  GET_PROFILE: 'user/profile',
}

export const BABY_VACCINE_PATHS = {
  POST_SUB_CHANNEL: 'frontend/api/channels/registrations?site={domain}',
  POST_SUB_CHANNEL_DETAIL: 'frontend/api/channels?site={domain}',
  GET_MAIN_TIMELINES: 'frontend/api/babies/{babyId}/schedules?site={domain}',
  POST_RECORD_TIMELINE:
    'frontend/api/babies/{babyId}/timelines/records?site={domain}',
  DELETED_RECORD_TIMELINE:
    'frontend/api/babies/{babyId}/timelines/records/{idRecord}?site={domain}',
  GET_RESULT_BABY_VACCINE_INFO: 'vaccination/result?site={domain}',

  POST_RECORD_IN_VACCINE_DETAIL:
    'frontend/api/babies/{babyId}/vaccines/{vaccineId}/records?site={domain}',
  DELETE_RECORD_IN_VACCINE_DETAIL:
    'frontend/api/babies/{babyId}/vaccines/{vaccineId}/records/{recordId}?site={domain}',
  PUT_BABY_DETAIL: 'frontend/api/babies/{babyId}?site={domain}',
  DELETE_BABY: 'frontend/api/babies/{babyId}?site={domain}',
  GET_BANNER_BABY_VACCINE: 'frontend/api/banners?site={domain}',
  ADD_MEASUREMENT: 'frontend/api/babies/{babyId}/measurements?site={domain}',
  GET_HEALTH_TOOL_BABY_VACCINE_INFO: 'vaccination/info?site={domain}',
  GET_HEALTH_TOOL_BABY_VACCINE_LIST: 'frontend/api/babies?site={domain}',
  GET_HEALTH_TOOL_BABY_DETAIL: 'frontend/api/babies/{babyId}?site={domain}',
  // New api
  GET_HEALTH_TOOL_BABY_DETAIL_V2:
    'frontend/api/v2/babies/{babyId}?site={domain}',
  GET_HEALTH_TOOL_BABY_IMMUNIZATION_V2:
    'frontend/api/v2/babies/{babyId}/statics?site={domain}',
  GET_VACCINE_DETAIL:
    'frontend/api/v2/babies/{babyId}/vaccines/{vaccineId}?site={domain}',
  ADD_INJECTED_DATE:
    'frontend/api/v2/babies/{babyId}/vaccines/{vaccineId}/records?site={domain}',
}
